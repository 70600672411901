import styled from "styled-components";
import Bullet from "assets/images/list-bullet.svg";
import { GlobalWrapper, TextWrapper } from "shared/Styles/Wrappers";
import { Paragraph, TitleXL } from "components/Typography";

export const Container = styled(GlobalWrapper)`
  padding-top: 124px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 72px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 48px;
  }
`;

export const StyledSectionWrapper = styled(TextWrapper)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

export const StyledTitleXL = styled(TitleXL)`
  margin-bottom: 36px;
  font-size: 4.8rem;
  font-weight: 800;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 3.6rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2.8rem;
  }
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 52px 62px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 34px;
    padding-right: 34px;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:nth-child(2) {
      margin-bottom: 36px;
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.black};
`;

export const DescTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 400;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1rem;
  }
`;

export const SalaryParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fonts.m};
  font-weight: 800;
  display: flex;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: ${({ theme }) => theme.fonts.s};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.xs};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 36px;
  position: relative;
  bottom: 5px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-right: 24px;
  }
`;

export const Img = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  object-position: center;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: 24px;
    height: 24px;
  }
`;

export const JobDetailSection = styled.section``;

export const JobDetailSectionTitle = styled(Paragraph)`
  font-weight: 800;
  margin-bottom: 36px;
  margin-top: 96px;
  font-size: 2.4rem;
  display: block;
  color: ${({ theme }) => theme.colors.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fonts.s};
    margin-top: 84px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin-top: 64px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.xxs};
    margin-bottom: 24px;
    margin-top: 48px;
  }
`;

export const StyledList = styled.ul`
  margin-bottom: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 36px;
  }
`;

export const ListItem = styled.li`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.4;
  position: relative;
  padding-left: 30px;
  max-width: 595px;
  color: ${({ theme }) => theme.colors.black};
  &:not(:first-child) {
    margin-top: 24px;
  }
  &::after {
    content: "";
    background-image: url(${Bullet});
    top: 8px;
    left: 0px;
    position: absolute;
    width: 8px;
    height: 8px;

    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tablet_big}) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 6px;
      height: 6px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 5px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fonts.xs};
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: ${({ theme }) => theme.fonts.xs};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.2rem;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;
export const ParallaxContainer = styled.div`
  display: flex;
  & > * {
    width: 33%;
  }
`;

export const JobDetailContainer = styled.div`
  max-width: 800px;
  position: relative;
`;

export const JobFormTitle = styled(TitleXL)`
  margin: 72px 0 48px 0;
`;

export const JobForm = styled(TextWrapper)`
  padding-left: 0;
  padding-right: 0;
`;
