import React                                                                                                                                                                                                                                            from "react";
import LocationIcon                                                                                                                                                                                                                                     from "../../../assets/images/location.svg";
import WalletIcon                                                                                                                                                                                                                                       from "../../../assets/images/wallet.svg";
import {Container as FormContainer, Form}                                                                                                                                                                                                               from "../../../components/Form";
import {CAREER_DETAILS_IFRAME_NAME}                                                                                                                                                                                                                     from "../../../components/Form/form";
import SEO                                                                                                                                                                                                                                              from "../../../components/seo";
import {ColumnWrapper, Container, DescTitle, DetailsWrapper, IconWrapper, Img, JobDetailContainer, JobDetailSection, JobDetailSectionTitle, JobForm, JobFormTitle, ListItem, SalaryParagraph, StyledList, StyledSectionWrapper, StyledTitleXL, Wrapper} from "./styles";

const JobLayout = ({pageContext}) => {
  return (
    <Container>
      <SEO
        title="Kariera"
        description="Zostań członkiem załogi InteractiveVision! Czujesz, że dzięki Tobie poszybujemy jeszcze wyżej? Być może czekamy właśnie na Ciebie!"
      />
      <StyledSectionWrapper>
        <Wrapper>
          <JobDetailContainer>
            <StyledTitleXL>{pageContext.job.jobName}</StyledTitleXL>
            <JobDetailSection>
              <DetailsWrapper>
                <IconWrapper>
                  <Img alt="" src={WalletIcon}/>
                </IconWrapper>
                <ColumnWrapper>
                  <DescTitle>Stawka</DescTitle>
                  <SalaryParagraph>{pageContext.job.salary}</SalaryParagraph>
                </ColumnWrapper>
              </DetailsWrapper>
              <DetailsWrapper>
                <IconWrapper>
                  <Img alt="" src={LocationIcon}/>
                </IconWrapper>
                <ColumnWrapper>
                  <DescTitle>Lokalizacja</DescTitle>
                  <SalaryParagraph>{pageContext.job.jobPlace}</SalaryParagraph>
                </ColumnWrapper>
              </DetailsWrapper>
            </JobDetailSection>
            {pageContext.job.tasks.length > 0 && (
              <>
                <JobDetailSectionTitle>Co Cię czeka?</JobDetailSectionTitle>
                <StyledList>
                  {pageContext.job.tasks.map((task) => (
                    <ListItem key={task}>{task}</ListItem>
                  ))}
                </StyledList>
              </>
            )}
            {pageContext.job.weoffer.length > 0 && (
              <>
                <JobDetailSectionTitle>
                  Co oferujemy w zamian?
                </JobDetailSectionTitle>
                <StyledList>
                  {pageContext.job.weoffer.map((offer) => (
                    <ListItem key={offer}>{offer}</ListItem>
                  ))}
                </StyledList>
              </>
            )}
            {pageContext.job.requirements.length > 0 && (
              <>
                <JobDetailSectionTitle>
                  Prześlij do nas CV jeśli:
                </JobDetailSectionTitle>
                <StyledList>
                  {pageContext.job.requirements.map((requirement) => (
                    <ListItem key={requirement}>{requirement}</ListItem>
                  ))}
                </StyledList>
              </>
            )}
            {pageContext.job.goodToHave.length > 0 && (
              <>
                <JobDetailSectionTitle>
                  Mile widziane:
                </JobDetailSectionTitle>
                <StyledList>
                  {pageContext.job.goodToHave.map((skill) => (
                    <ListItem key={skill}>{skill}</ListItem>
                  ))}
                </StyledList>
              </>
            )}
          </JobDetailContainer>
          <JobForm>
            <JobFormTitle underline>Dołącz do nas!</JobFormTitle>
            <FormContainer>
              <Form
                src={process.env.GATSBY_CAREER_FORM_URL + `?department=${pageContext.job.formKey}`}
                name={CAREER_DETAILS_IFRAME_NAME}
              />
            </FormContainer>
          </JobForm>
        </Wrapper>
      </StyledSectionWrapper>
    </Container>
  );
};

export default JobLayout;
